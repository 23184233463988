<template>
  <div class="text-noti"></div>
  <!--  <div class="text-noti f-w6">このアプリはテストリリース中になります。</div>-->
</template>
<script>
export default {
  name: "NotifyHeader"
};
</script>
<style lang="scss">
@import "../assets/scss/_fontFamily.scss";
.text-noti {
  //font-size: 16px;
  //color: #ffffff;
  //background: #4cb3be;
  //height: 32px;
  padding-top: 4px;
  //top: 42px;
  position: fixed;
  z-index: 10;
  width: 100%;
  max-width: 1080px;
}
@media screen and (min-width: 1200px) {
  .text-noti {
    //top: 61px !important;
  }
}
</style>
