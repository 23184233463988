<template>
  <div id="myPage-editProfile">
    <card-header ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="page-editProfile">
        <div class="menu__item">
          <div class="menu__item__content" @click="nextEditProfile()">
            <div>
              <span> プロフィール編集</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="menu__item" @click="nextRoundLog()">
          <div class="menu__item__content">
            <div>
              <span> ラウンドログ・評価</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="menu__item" @click="nextPersonalInformation()">
          <div class="menu__item__content menu__item__cursor">
            <div>
              <span> 個人情報</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="menu__item" @click="nextIdentifyProfile">
          <div class="menu__item__content">
            <div>
              <span> 身分証明書の確認(更新)</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <card-footer ref="footer" />
  </div>
</template>
<script>
import CardFooter from "@/components/CardFooter";
import CardHeader from "@/components/CardHeader";

export default {
  name: "MyPage-EditProfile",
  components: {
    "card-footer": CardFooter,
    "card-header": CardHeader
  },
  metaInfo() {
    return {
      title: "プロフィール編集",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "プロフィール編集",
        isShowTitle: true,
        isShowLogo: false
      }
    };
  },
  methods: {
    nextEditProfile() {
      const user = this.$store.getters["auth/user"];
      this.$root.$refs.loading.start();
      if (user.sex === 1) {
        this.$router.push({ name: "EditProfile" });
      } else {
        this.$router.push({ name: "EditProfileCast" });
      }
      localStorage.removeItem("gender");
      this.$root.$refs.loading.finish();
    },
    nextRoundLog() {
      this.$root.$refs.loading.start();
      this.$router.push({
        name: "CastRoundLogList",
        query: {
          sex: 2
        }
      });
      this.$root.$refs.loading.finish();
    },
    nextIdentifyProfile() {
      this.$root.$refs.loading.start();
      this.$router.push({ name: "CastMyPageIdentifyProfile" });
      this.$root.$refs.loading.finish();
    },
    nextPersonalInformation() {
      this.$root.$refs.loading.start();
      this.$router.push({ name: "CastMyPagePersonalInformationEdit" });
      this.$root.$refs.loading.finish();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/cast/myPage-EditProfile.scss";

.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    padding-top: 60px;
    // height: calc(100vh - 152px);
  }
}
</style>
