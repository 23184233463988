<template>
  <button class="btn-back" @click="backRoute">
    <img :src="require('@/assets/image/arrow-back.svg')" alt="back" />
  </button>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ButtonBack",
  props: {
    go: String,
    goRoute: [Object, String, null],
    step: Boolean
  },

  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    async backRoute() {
      if (this.goRoute) {
        this.$router.push(this.goRoute);
      } else if (this.$route.query.order == "success") {
        this.$router.go(-3);
      } else if (
        this.$route.name == "CastProfile" ||
        this.$route.name == "MenProfile"
      ) {
        if (this.$route.query.showPopup) {
          if (this.user.sex === 1) {
            this.$router.push({
              name: "Home",
              params: {
                id: this.$route.params.id,
                type: this.$route.query.type,
                showPopup: true
              }
            });
          } else {
            this.$router.push({
              name: "CastHome",
              params: {
                id: this.$route.params.id,
                showPopup: true
              }
            });
          }
        } else {
          await this.$router.go(-1);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      } else if (this.$route.query.create == "success") {
        this.$router.push({ name: "Home" });
      } else if (this.go == null) {
        this.$router.go(-1);
      } else {
        this.$router.push({ path: this.go });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  padding: 0;
  background: transparent;
  img {
    display: block;
    width: 8px;
    height: 14px;
  }
}
@media screen and (min-width: 1200px) {
  button {
    img {
      height: 23px;
      width: 14px;
    }
  }
}
</style>
