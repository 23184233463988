<template>
  <div>
    <div
      class="card-header-body"
      style="position: fixed; top: 0; right: 0; left: 0; margin-auto"
    >
      <div v-if="node.isShowLogo" class="header-logo">
        <img
          class="logo"
          alt="logo-prego"
          src="../assets/image/logo-prego.png"
        />
      </div>
      <div
        class="header-card d-flex align-items-center"
        :class="{
          'justify-content-center': !node.isTimeLine,
          'justify-content-between': node.isTimeLine
        }"
        v-if="node.isShowTitle"
      >
        <ButtonBack
          :go="node.back"
          :goRoute="node.backTo"
          :step="node.step"
          @back-tutorial="backTutorial"
          v-if="!node.isTimeLine"
        />
        <div
          class="position-relative"
          v-if="node.isTimeLine"
          style="cursor: pointer"
          @click="$router.push({ name: 'Notification' })"
        >
          <img
            :src="require('@/assets/image/timeline/time-line-notification.svg')"
            class="icon-notification"
            alt=""
          />
          <div
            v-if="numberNoti > 0"
            class="
              number-notification
              d-flex
              justify-content-center
              align-items-center
              f-w3
            "
            :class="numberNoti >= 100 ? 'number-notification-long' : ''"
          >
            {{ numberNoti }}
          </div>
        </div>
        <span class="text-center f-w6">{{ node.text }}</span>
        <b-icon
          icon="three-dots"
          class="popup-block__btn icon"
          @click="close()"
          @blur="show = false"
          :tabindex="tabindex"
          v-if="node.iconDot"
        />
        <img
          v-if="node.isTimeLine"
          :src="require('@/assets/image/timeline/time-line-edit.svg')"
          class="icon-edit"
          alt=""
          @click="editTimeline"
        />
        <img
          v-if="node.isTimeLine"
          :src="require('@/assets/image/timeline/time-line-edit-active.svg')"
          class="icon-edit active"
          alt=""
          @click="editTimeline"
        />
      </div>
      <transition name="slide-down">
        <div
          class="popup-block__div d-flex justify-content-start align-items-center"
          v-if="show && node.iconDot"
        >
          <div
            class="
              d-flex
              justify-content-start
              align-items-center
              popup-block__div-option
            "
            @click="block()"
          >
            <img
              src="@/assets/image/icon_profile/icon-block.svg"
              class="image__block"
              alt=""
            />
            <pre>ブロック</pre>
          </div>
        </div>
      </transition>
    </div>
    <NotifyHeader />
  </div>
</template>

<script>
import ButtonBack from "../views/Common/ButtonBack.vue";
import NotifyHeader from "@/components/NotifyHeader.vue";

export default {
  components: { ButtonBack, NotifyHeader },
  name: "CardHeader",
  props: {
    node: Object,
    tabindex: {
      type: Number,
      require: null,
      default: 0
    },
    numberNoti: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false
    };
  },

  methods: {
    close() {
      this.show = !this.show;
    },
    editTimeline() {
      this.$emit("editTimeline");
    },
    block() {
      this.$emit("blockUser");
    },
    backTutorial() {
      this.$emit("back-tutorial");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/cardHeader.scss";
@import "../assets/scss/_fontFamily.scss";
</style>
