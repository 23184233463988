<template>
  <!-- <div class="d-flex justify-content-center"> -->
  <div
    id="card-footer"
    style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 10"
  >
    <div
      class="footer text-center d-flex align-items-center"
      :class="{ default: defaultStyle }"
    >
      <div
        class="
          option
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
        v-for="(item, key) in navigations"
        :key="key"
      >
        <router-link
          v-if="
            item.id !== 'timeline' &&
              item.id !== 'order' &&
              item.id !== 'message'
          "
          :to="item.routeName"
          :exact="item.exact"
          class="d-flex flex-column align-items-center justify-content-center"
          :class="prefer === item.id ? 'prefer' : ''"
          :id="item.id"
        >
          <div class="option-icon" :id="item.id">
            <img
              :src="require('@/assets/image/footer/' + item.imgSrc + '.svg')"
              :alt="item.value"
            />
            <img
              :src="
                require('@/assets/image/footer/' + item.imgSrc + '-active.svg')
              "
              :alt="item.value"
              class="_active"
            />
            <div
              v-if="item.id == 'mypage' && totalNotReadAds['apply'] > 0"
              class="notification position-absolute"
            ></div>
          </div>
          <!-- <span>{{ item.value }}</span> -->
        </router-link>
        <router-link
          v-if="
            item.id == 'order' &&
              (user.sex == 2 || (limit == 0 && user.sex == 1))
          "
          :to="item.routeName"
          :exact="item.exact"
          class="d-flex flex-column align-items-center justify-content-center"
          :class="prefer === item.id ? 'prefer' : ''"
        >
          <div class="option-icon" @click="resetOrder()">
            <img
              :src="require('@/assets/image/footer/' + item.imgSrc + '.svg')"
              :alt="item.value"
            />
            <img
              :src="
                require('@/assets/image/footer/' + item.imgSrc + '-active.svg')
              "
              :alt="item.value"
              class="_active"
            />
            <div
              v-if="totalNotReadAds['designate'] + totalNotReadAds['match'] > 0"
              class="notification position-absolute"
            ></div>
          </div>
          <!-- <span>{{ item.value }}</span> -->
        </router-link>
        <router-link
          v-if="item.id == 'message'"
          :exact="item.exact"
          class="d-flex flex-column align-items-center justify-content-center"
          :class="prefer === item.id ? 'prefer' : ''"
          style="cursor: pointer"
          :to="item.routeName"
        >
          <div class="option-icon">
            <img
              :src="require('@/assets/image/footer/' + item.imgSrc + '.svg')"
              :alt="item.value"
            />
            <img
              :src="
                require('@/assets/image/footer/' + item.imgSrc + '-active.svg')
              "
              :alt="item.value"
              class="_active"
            />
            <div
              class="number-message"
              :class="notify >= 100 ? 'notify-round' : 'notify-circle'"
              v-if="item.id == 'message' && notify > 0"
            >
              {{ notify }}
            </div>
          </div>
          <!-- <span>{{ item.value }}</span> -->
        </router-link>
        <div
          v-if="item.id == 'order' && limit > 0"
          :exact="item.exact"
          class="d-flex flex-column align-items-center justify-content-center"
          :class="prefer === item.id ? 'prefer' : ''"
        >
          <div class="option-icon">
            <img
              :src="require('@/assets/image/footer/' + item.imgSrc + '.svg')"
              :alt="item.value"
            />
            <img
              :src="
                require('@/assets/image/footer/' + item.imgSrc + '-active.svg')
              "
              :alt="item.value"
              class="_active"
            />
            <div
              v-if="totalNotReadAds['designate'] + totalNotReadAds['match'] > 0"
              class="number-message position-absolute"
            ></div>
          </div>
          <!-- <span>{{ item.value }}</span> -->
        </div>
        <div
          v-if="item.id == 'timeline'"
          class="d-flex flex-column align-items-center justify-content-center"
          :exact="item.exact"
          :class="prefer === item.id ? 'prefer' : ''"
          style="cursor: pointer"
          @click="showTimeline()"
        >
          <div class="option-icon">
            <img
              :src="require('@/assets/image/footer/' + item.imgSrc + '.svg')"
              :alt="item.value"
            />
            <img
              :src="
                require('@/assets/image/footer/' + item.imgSrc + '-active.svg')
              "
              :alt="item.value"
              class="_active"
            />
          </div>
          <!-- <span>{{ item.value }}</span> -->
        </div>
      </div>
    </div>
    <ModalLayout ref="identifyPopup">
      <IdentifyPopup
        @identify="identify"
        :identity_status="user.identity_status"
        :sex="2"
      />
    </ModalLayout>
  </div>

  <!-- </div> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IdentifyPopup from "@/components/IdentifyPopup.vue";

export default {
  name: "CardFooter",

  components: {
    IdentifyPopup
  },
  data() {
    return {
      notify: ""
    };
  },
  watch: {
    firebaseUnread() {
      if (this.firebaseUnread.length > 0) {
        this.notify = this.firebaseUnread.reduce((totalUnread, unread) => {
          totalUnread += unread.notify;
          return totalUnread;
        }, 0);
      }
    }
  },
  props: {
    defaultStyle: {
      type: Boolean,
      default: true
    },
    prefer: {
      type: String
    }
  },
  mounted() {
    const footer = document.getElementById("card-footer");
    footer.addEventListener("touchstart", swipe);
    footer.addEventListener("touchmove", swipe);

    function swipe(event) {
      if (event.type == "touchmove") {
        event.preventDefault();
      }
    }
  },
  async created() {
    await this.$store.dispatch("payment-stripe/getCreditCard");
    await this.$store.dispatch("auth/getTotalNotReadAds");
    await this.$store.dispatch("areaLimit/getLimitArea");
    if (this.$route.name != "Messages" && this.$route.name != "MessageDetail") {
      await this.$store.dispatch("firebase/getUnread", {
        userId: this.user.user_id
      });
    }
    if (this.$route.params.id) {
      this.setRoomId(this.$route.params.id);
    } else {
      this.setRoomId(null);
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      cardInfo: "payment-stripe/cardInfo",
      totalNotReadAds: "auth/totalNotReadAds",
      limit: "areaLimit/limit_area",
      firebaseUnread: "firebase/unread"
    }),
    navigations() {
      // const orderId = window.location.pathname.includes(
      //   "/create-order/step-one"
      // )
      //   ? this.$route.params.id
      //   : "";
      let gender = "/";
      if (this.user.sex === 2) {
        gender = "/cast/";
      }
      return [
        {
          id: "home",
          value: "ホーム",
          routeName: gender,
          imgSrc: "ホーム",
          exact: true
        },
        {
          id: "message",
          value: "メッセージ",
          routeName: "/messages",
          imgSrc: "メッセージ",
          exact: false
        },
        // {
        //   id: "order",
        //   value: "オーダー",
        //   routeName:
        //     this.user.sex === 1
        //       ? `/create-order${orderId ? "/" + orderId : ""}`
        //       : "/cast/orders",
        //   imgSrc: "オーダー",
        //   exact: false
        // },
        // {
        //   id: "timeline",
        //   value: "つぶやき",
        //   routeName: "",
        //   imgSrc: "つぶやき",
        //   exact: false
        // },
        {
          id: "mypage",
          value: "マイページ",
          routeName: gender + "my-page",
          imgSrc: "マイページ",
          exact: false
        }
      ];
    }
  },
  methods: {
    ...mapActions("firebase", ["getListRoom", "setRoomId"]),

    checkIsCredit() {
      if (this.user.sex == 1) {
        this.$refs.creditPopup.openModal();
      } else {
        this.$router.push({ path: "/cast/orders" });
      }
    },
    showTimeline() {
      if (this.user.identity_status != 3) {
        this.$refs.identifyPopup.openModal();
        return;
      } else if (
        this.$route.name != "MenTimeLine" &&
        this.$route.name != "CastTimeLine"
      ) {
        if (this.user.sex == 1) {
          this.$router.push({ name: "MenTimeLine" });
          return;
        }
        this.$router.push({ name: "CastTimeLine" });
      }
    },
    // checkidentity() {
    //   if (this.user.identity_status != 3) {
    //     this.$refs.identifyPopup.openModal();
    //     return;
    //   }
    //   this.$router.push({ path: "/messages" });
    // },
    resetOrder() {
      if (this.$route.name != "MenCreateOrderStepOne") {
        this.$store.dispatch("orderManagement/resetOrder");
      }
    },
    identify() {
      if (this.user.sex == 1) {
        if (this.$route.name != "MenMyPageIdentifyProfile")
          this.$router.push({ name: "MenMyPageIdentifyProfile" });
        this.$refs.identifyPopup.closeModal();
        return;
      }
      if (this.$route.name != "CastMyPageIdentifyProfile")
        this.$router.push({ name: "CastMyPageIdentifyProfile" });
      this.$refs.identifyPopup.closeModal();
    },
    checkIsCreditIdentify() {
      if (this.user.sex == 1) {
        this.$refs.creditIdentifyPopup.openModal();
      } else {
        this.$refs.identifyPopup.openModal();
      }
    },
    cancelCredit() {
      this.$refs.creditPopup.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/cardFooter.scss";

.notification {
  border-radius: 50%;
  background-color: #eb5050;
  height: 15px;
  width: 15px;
  top: -5px;
  right: -5px;
  z-index: 20;
}
@media screen and (min-width: 1200px) {
  .notification {
    height: 20px;
    width: 20px;
    right: -5px;
    top: -5px;
  }
}
</style>
